import {
  Badge,
  BadgeTypeMap,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuItemProps,
  withStyles,
} from '@material-ui/core';
import { SvgIconComponent } from '@material-ui/icons';
import React from 'react';

const MenuItemWithStyles = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.secondary,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.black,
      },
    },
  },
}))(MenuItem);

export type StyledMenuItemProps = MenuItemProps & {
  label: string;
  Icon?: SvgIconComponent;
  iconClassName?: string;
  badge?: BadgeTypeMap['props'];
  customBadge?: React.ComponentType;
};

export const StyledMenuItem = React.forwardRef<
  HTMLLIElement,
  StyledMenuItemProps
>((props, ref) => {
  const {
    Icon,
    label,
    badge,
    customBadge: CustomBadge,
    iconClassName,
    ...itemProps
  } = props;
  return (
    <MenuItemWithStyles
      {...{
        ...itemProps,
        button: true,
      }}
      ref={ref}
    >
      {Icon && (
        <>
          {badge && (
            <ListItemIcon>
              <Badge {...badge} color="primary">
                <Icon fontSize="small" className={iconClassName} />
              </Badge>
            </ListItemIcon>
          )}

          {CustomBadge && (
            <ListItemIcon>
              <CustomBadge>
                <Icon fontSize="small" className={iconClassName} />
              </CustomBadge>
            </ListItemIcon>
          )}

          {!badge && !CustomBadge && (
            <ListItemIcon>
              <Icon fontSize="small" className={iconClassName} />
            </ListItemIcon>
          )}
        </>
      )}
      <ListItemText primary={label} />
    </MenuItemWithStyles>
  );
});
