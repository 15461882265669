import { ButtonGroup } from '@material-ui/core';
import React from 'react';
import * as styles from './verticalTextButtonGroupStyles.module.scss';

export const VerticalTextButtonGroup: React.FC = (props) => (
  <ButtonGroup
    size="small"
    orientation="vertical"
    color="secondary"
    variant="text"
    className={styles.buttonGroup}
  >
    {props.children}
  </ButtonGroup>
);
