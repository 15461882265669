import { TConfig } from '../TConfig';

export const config: TConfig = {
  ENV: 'prod',
  INTEGRATOR_BACKEND_URL: 'https://integrator.service.foodcards.de/',
  APP_ASSETS_URL: 'https://ik.imagekit.io/5gznnawhlz/app-assets/',
  GOOGLE_TAG_MANAGER_ID: 'GTM-5BSXVLK',
  FULLSTORY_ORG_ID: '1484AA',
  SENTRY_DSN:
    'https://b0aa7115567845c288617dff29d8db58@o927820.ingest.sentry.io/5877353',
  SENTRY_SAMPLE_RATE: 0.1,
  SWITCHOVER_API_KEY: 'cc9bee7f-e470-403e-a361-363513aec295',
};
