import { Store } from '@reduxjs/toolkit';
import { DASH_URL } from '@shared/core';
import { LoadingView } from '@shared/ui';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ReducersMapObject } from 'redux';
import { getAppConfigs } from '../../serviceClients/integratorBackendServiceClient';
import { initSwitchoverClient } from '../../serviceClients/switchoverServiceClient';
import { TAppConfig } from '../../types/TAppConfig';
import { getLazyComponent } from '../../utils/getLazyComponent';
import { initSystemJS } from '../../utils/initSystemJS';
import { addReducers, store } from '../../utils/store';
import { initGoogleTagManager } from '../../utils/tracking/initGoogleTagManager';
import './integratorStyles.module.scss';
type State = {
  configs: TAppConfig[];
};

type Props = {
  app?: {
    name: string;
    route: string;
    component: React.ComponentType<{ store: Store }> & {
      reducers?: ReducersMapObject;
    };
  };
};

initGoogleTagManager();

export class Integrator extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      configs: [],
    };

    if (this.props.app && this.props.app.component.reducers) {
      addReducers(this.props.app.component.reducers);
    }
  }

  componentDidMount = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const branch = urlParams.get('branch');
    if (branch) {
      console.warn(`Apps for branch: ${branch}`);
    }

    await Promise.all([
      initSwitchoverClient(),
      getAppConfigs(branch).then((configs) => {
        initSystemJS(configs);
        this.setState({
          configs,
        });
      }),
    ]);
  };

  handleAppMount = (appName: string) => {
    // add this global class, in order to be able to apply global body styling from within an app
    document.body.className = `${appName}-app`.toLowerCase();
  };

  render = () => {
    if (!this.state.configs.length) {
      return <LoadingView />;
    }

    return (
      <Provider store={store}>
        <React.Suspense fallback={<LoadingView />}>
          <BrowserRouter>
            <Switch>
              {this.props.app && (
                <Route
                  path={this.props.app.route}
                  render={() => {
                    this.handleAppMount(this.props.app!.name);
                    const Component = this.props.app!.component;
                    return <Component store={store} />;
                  }}
                />
              )}
              {this.state.configs.map((config) => {
                const LazyComponent = getLazyComponent(
                  config,
                  addReducers,
                  this.handleAppMount
                );
                return (
                  <Route
                    key={config.route}
                    path={config.route}
                    render={() => <LazyComponent store={store} />}
                  />
                );
              })}
              <Redirect path="*" to={DASH_URL} />
            </Switch>
          </BrowserRouter>
        </React.Suspense>
      </Provider>
    );
  };
}
