import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import React, { useMemo } from 'react';

type Props = {
  cssClassPrefix: string;
};

export const MuiSetup: React.FC<Props> = (props) => {
  const generateClassName = useMemo<ReturnType<typeof createGenerateClassName>>(
    () =>
      createGenerateClassName({
        seed: props.cssClassPrefix,
      }),
    [props.cssClassPrefix]
  );

  return (
    <StylesProvider generateClassName={generateClassName}>
      {props.children}
    </StylesProvider>
  );
};
