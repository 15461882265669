import React from 'react';
type Props = {
  className?: string;
};
export const Icon: React.FC<Props> = (props) => (
  <span
    className={props.className}
    dangerouslySetInnerHTML={{
      __html: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64"><defs><style>.cls-1{fill:#ffbe58;}</style></defs><path class="cls-1" d="M32,0A32,32,0,0,0,21,62.07c.33-6,.85-13.78,1.48-19.51a3.86,3.86,0,0,0-1.84-3.74A6.84,6.84,0,0,1,17.35,33l1-16.07a1.16,1.16,0,0,1,2.32.13L20,29.89a1.32,1.32,0,1,0,2.63,0L23,17a1.26,1.26,0,0,1,1.26-1.22A1.24,1.24,0,0,1,25.46,17l.32,12.88a1.32,1.32,0,1,0,2.63,0L27.77,17a1.15,1.15,0,0,1,1.16-1.22,1.17,1.17,0,0,1,1.16,1.09l1,16.07a6.86,6.86,0,0,1-3.31,5.87,3.89,3.89,0,0,0-1.85,3.74c.7,6.26,1.25,15,1.58,21.12A31.92,31.92,0,0,0,32,64a32.47,32.47,0,0,0,5.79-.53c.28-5.31.74-12.42,1.33-18.43h-1a2.39,2.39,0,0,1-2.38-2.39V31.19a29.68,29.68,0,0,1,6-17.93.7.7,0,0,1,.79-.24.69.69,0,0,1,.47.66V45c.51,5.2.92,11.46,1.21,16.54A32,32,0,0,0,32,0Z"/></svg>
      `,
    }}
  />
);
