import { getConfig } from '../config/getConfig';
import { TAppConfig } from '../types/TAppConfig';
import { get } from './serviceClient';

export const getAppConfigs = (branch: string | null) =>
  get<TAppConfig[]>(
    `${getConfig('INTEGRATOR_BACKEND_URL')}apps.php${
      branch ? `?branch=${branch}` : ''
    }`
  );
