import * as FullStory from '@fullstory/browser';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import { Integrator } from './components/Integrator/Integrator';
import { getConfig } from './config/getConfig';
import * as serviceWorker from './serviceWorker';

FullStory.init({
  orgId: getConfig('FULLSTORY_ORG_ID'),
  devMode: getConfig('ENV') !== 'prod',
});

Sentry.init({
  dsn: getConfig('SENTRY_DSN'),
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: getConfig('SENTRY_SAMPLE_RATE'),
  environment: getConfig('ENV'),
});

ReactDOM.render(
  /* <React.StrictMode> re-enable strict mode once material ui has solved its issues */
  <Integrator />,
  /* </React.StrictMode> */
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
