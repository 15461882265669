import { loadScriptByUrl } from '@shared/utils';
import { getConfig } from '../../config/getConfig';

const global = window as any;

let isInitialized = false;
export const initGoogleTagManager = () => {
  const googleTagManagerId = getConfig('GOOGLE_TAG_MANAGER_ID');
  if (!isInitialized && googleTagManagerId) {
    isInitialized = true;

    global.dataLayer = global.dataLayer || [];
    global.dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });

    loadScriptByUrl(
      `https://www.googletagmanager.com/gtm.js?id=${googleTagManagerId}`
    );
  }
};
