import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  withStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import { COLORS } from '../../constants';

type TOption = { id: string; label: string };

type Props = {
  options: TOption[];
  selected: { id: string }[];
  onChange: (options: TOption[]) => void;
  fullWidth?: boolean;
  disabled?: boolean;
  displayEmpty?: boolean;
  emptySelectionLabel?: string;
};

const renderValue = (options: TOption[]) =>
  options.map((o) => o.label).join(', ');

const MenuItemWithStyles = withStyles((theme) => ({
  root: {
    '&.Mui-selected': {
      backgroundColor: 'transparent',
    },
  },
}))(MenuItem);

export const MultiSelect: React.FC<Props> = (props) => {
  const [anchorPosition, setAnchorPosition] = useState({ top: 0, left: 0 });

  const setRef = (ref: HTMLDivElement | null) => {
    if (ref) {
      const rect = ref.getBoundingClientRect();
      if (
        rect.top !== anchorPosition.top &&
        rect.left !== anchorPosition.left
      ) {
        setTimeout(() => {
          setAnchorPosition({
            left: rect.left,
            top: rect.top + rect.height,
          });
        });
      }
    }
  };

  return (
    <FormControl
      style={{
        width: props.fullWidth ? '100%' : undefined,
      }}
      size="small"
      ref={setRef}
    >
      <Select
        variant="outlined"
        multiple
        value={props.selected.map((o) => o.id)}
        disabled={props.disabled}
        onChange={(ev) => {
          const ids = ev.target.value as string[];
          props.onChange(
            ids.map((id) => props.options.find((o) => o.id === id)!)
          );
        }}
        displayEmpty={props.displayEmpty}
        renderValue={(_ids) => {
          const ids = _ids as string[];
          const options = props.options.filter((o) => ids.includes(o.id));
          return renderValue(options) || props.emptySelectionLabel || '';
        }}
        MenuProps={{
          anchorOrigin: {
            horizontal: 'left',
            vertical: 'top',
          },
          transformOrigin: {
            horizontal: 'left',
            vertical: 'top',
          },
          anchorReference: 'anchorPosition',
          anchorPosition,
          PaperProps: {
            elevation: 1,
            style: {
              border: `1px solid ${COLORS.LIGHT}`,
            },
          },
          MenuListProps: {
            style: {
              padding: 0,
            },
          },
        }}
      >
        {props.options.map((option) => (
          <MenuItemWithStyles key={option.id} value={option.id}>
            <Checkbox
              checked={
                !!props.selected.find((selected) => selected.id === option.id)
              }
              color="primary"
              size="small"
            />
            <ListItemText primary={option.label} />
          </MenuItemWithStyles>
        ))}
      </Select>
    </FormControl>
  );
};
