import { Menu, MenuProps, withStyles } from '@material-ui/core';
import React from 'react';
import { BASE_UNIT, COLORS } from '../../constants';
import { getMenuPosition } from './getMenuPosition';
import { TMenuPositionKey } from './TMenuPositionKey';

const GAP = BASE_UNIT * 2;
const SMALL_GAP = BASE_UNIT;

type Props = MenuProps & {
  position: TMenuPositionKey;
  gap?: boolean | 'small';
};
export const StyledMenu = withStyles({
  paper: {
    border: `1px solid ${COLORS.LIGHT}`,
  },
})((props: Props) => {
  const { position, gap, ...menuProps } = props;
  const pos = getMenuPosition(position);
  const style: React.CSSProperties = {
    padding: 0,
  };
  if (gap) {
    const distance = gap === 'small' ? SMALL_GAP : GAP;
    if (
      pos.anchorOrigin.horizontal === 'left' &&
      pos.anchorOrigin.vertical === 'center'
    ) {
      style.marginLeft = -distance;
    }
    if (
      pos.anchorOrigin.horizontal === 'right' &&
      pos.anchorOrigin.vertical === 'center'
    ) {
      style.marginLeft = distance;
    }

    if (pos.anchorOrigin.vertical !== 'center') {
      style.marginTop =
        pos.anchorOrigin.vertical === 'top' ? -distance : distance;
    }
  }

  return (
    <Menu
      PaperProps={{ style }}
      MenuListProps={{ style: { padding: 0 } }}
      elevation={1}
      getContentAnchorEl={null}
      anchorOrigin={pos.anchorOrigin}
      transformOrigin={pos.transformOrigin}
      keepMounted
      disableAutoFocusItem
      {...menuProps}
    />
  );
});
