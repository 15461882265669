import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Typography,
} from '@material-ui/core';
import React from 'react';

export const CircularProgressWithLabel: React.FC<CircularProgressProps> = (
  props
) => {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress {...props} />
      {!!props.value && (
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="caption"
            component="div"
            color="textSecondary"
          >{`${Math.round(Math.min(100, props.value))}%`}</Typography>
        </Box>
      )}
    </Box>
  );
};
