import { Typography } from '@material-ui/core';
import React from 'react';
import { COLORS } from '../../../constants';
import * as styles from './pieProgressIndicatorStyles.module.scss';

type Props = {
  value: number;
};

// pie example: https://seesparkbox.com/foundry/how_to_code_an_SVG_pie_chart
export const PieProgressIndicator: React.FC<Props> = ({ value }) => (
  <div className={styles.circle}>
    <Typography className={styles.number} variant="caption">
      {`${Math.round(Math.min(100, value))}%`}
    </Typography>
    <div
      className={styles.svgWrapper}
      dangerouslySetInnerHTML={{
        __html: `
          <svg height="20" width="20" viewBox="0 0 20 20">
            <circle
              r="5"
              cx="10"
              cy="10"
              fill="rgba(0,0,0,0)"
              stroke="${COLORS.YELLOW}"
              stroke-width="10"
              stroke-dasharray="calc(${value / 100} * 31.42) 31.42"
            />
          </svg>`,
      }}
    />
  </div>
);
