import { Badge, BadgeTypeMap, Button, IconButton } from '@material-ui/core';
import { SvgIconComponent } from '@material-ui/icons';
import React from 'react';

type Props = {
  label?: string;
  icon: SvgIconComponent;
  endIcon?: SvgIconComponent;
  onClick: () => void;
  badge?: BadgeTypeMap['props'];
  disabled?: boolean;
  active?: boolean;
  customBadge?: React.ComponentType;
};

export const AppTopBarButton = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      label,
      icon: Icon,
      endIcon: EndIcon,
      customBadge: CustomBadge,
      onClick,
      badge,
      disabled,
      active,
    },
    ref
  ) => {
    if (label) {
      return (
        <Button
          ref={ref}
          style={{ fontWeight: 400 }}
          color={active ? 'primary' : 'inherit'}
          size="medium"
          startIcon={
            badge ? (
              <Badge {...badge} color="primary">
                <Icon fontSize="small" />
              </Badge>
            ) : CustomBadge ? (
              <CustomBadge>
                <Icon fontSize="small" />
              </CustomBadge>
            ) : (
              <Icon fontSize="small" />
            )
          }
          endIcon={EndIcon && <EndIcon />}
          onClick={onClick}
          disabled={disabled}
        >
          {label}
        </Button>
      );
    }

    return (
      <IconButton ref={ref} onClick={onClick} disabled={disabled} size="small">
        {badge && (
          <Badge {...badge} color="primary">
            <Icon fontSize="small" />
          </Badge>
        )}

        {!badge && <Icon fontSize="small" />}
      </IconButton>
    );
  }
);
