import * as Switchover from 'switchover-js-sdk';
import { getConfig } from '../config/getConfig';

type TSwitchoverClient = {
  hasFeature: (name: string, defaultValue: boolean, context?: {}) => boolean;
};
const global = window as any as {
  _switchoverClient: TSwitchoverClient;
};
global._switchoverClient = global._switchoverClient || {
  hasFeature: () => false,
};

export const initSwitchoverClient = async () => {
  const switchoverClient = Switchover.createClient(
    getConfig('SWITCHOVER_API_KEY'),
    {},
    getConfig('ENV') === 'dev' ? 'info' : 'error'
  );

  global._switchoverClient.hasFeature = (
    name: string,
    defaultValue: boolean,
    context?: {}
  ) => !!switchoverClient.toggleValue(name, defaultValue, context);

  await switchoverClient.fetchAsync();
};

export const hasFeature = (name: string, defaultValue: boolean, context?: {}) =>
  global._switchoverClient.hasFeature(name, defaultValue, context);
