import React from 'react';
import { History } from 'history';
import { useHistory } from 'react-router-dom';

type Props = {
  children: (props: { history: History }) => React.ReactElement;
};

export const HistoryProvider: React.FC<Props> = (props) => {
  const history = useHistory();
  return props.children({ history });
};
