import { CircularProgress } from '@material-ui/core';
import React from 'react';

export const CenteredLoadingSpinner: React.FC = () => (
  <CircularProgress
    color="primary"
    style={{
      position: 'absolute',
      top: 'calc(50% - 20px)',
      left: 'calc(50% - 20px)',
      zIndex: 1,
    }}
  />
);
