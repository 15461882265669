import { Typography, TypographyProps } from '@material-ui/core';
import React from 'react';
import * as styles from './singleLineTypography.module.scss';
import classnames from 'classnames';

type Props = TypographyProps & { component?: React.ElementType };

export const SingleLineTypography: React.FC<Props> = (props) => (
  <Typography
    {...props}
    className={classnames(styles.typography, props.className)}
  />
);
