import { configureStore } from '@reduxjs/toolkit';
import { combineReducers, ReducersMapObject } from 'redux';

let asyncReducers: ReducersMapObject = {};
const staticReducers: ReducersMapObject = {
  app: (s: {} = {}, a) => s,
};
export const store = configureStore({
  reducer: {
    ...staticReducers,
  },
});

export const addReducers = (reducers: ReducersMapObject) => {
  asyncReducers = {
    ...asyncReducers,
    ...reducers,
  };
  store.replaceReducer(
    combineReducers({
      ...staticReducers,
      ...asyncReducers,
    })
  );
};
