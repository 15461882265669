import React from 'react';
import * as styles from './loadingViewStyles.module.scss';
import { Icon } from './Icon';
import classnames from 'classnames';

type Props = {
  disableAnimation?: boolean;
};

export const LoadingView: React.FC<Props> = (props) => (
  <div className={styles.container}>
    <div className={styles.imagesWrapper}>
      <Icon
        className={classnames(styles.circle, styles.circle1, {
          [styles.pulse]: !props.disableAnimation,
        })}
      />
      <Icon
        className={classnames(styles.circle, styles.circle2, {
          [styles.pulse]: !props.disableAnimation,
        })}
      />
      <Icon
        className={classnames(styles.circle, styles.circle3, {
          [styles.pulse]: !props.disableAnimation,
        })}
      />
    </div>
  </div>
);
