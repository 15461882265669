import { Tooltip as MuiTooltip, withStyles } from '@material-ui/core';
import { COLORS } from '../../constants';

export const Tooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: COLORS.WHITE,
    color: COLORS.DARK,
    boxShadow: theme.shadows[1],
    fontSize: 11,
    fontWeight: 400,
    // use span here because of custom mui prefixing in the apps
    '& span::before': {
      backgroundColor: COLORS.WHITE,
      boxShadow: theme.shadows[1],
    },
  },
}))(MuiTooltip);
