import React from 'react';
import { getConfig } from '../config/getConfig';
import { loadCss } from './loadCss';
import { TAppConfig } from '../types/TAppConfig';
import { ReducersMapObject, Store } from '@reduxjs/toolkit';
import { SystemJS } from '../SystemJS';

export const getLazyComponent = (
  config: TAppConfig,
  addReducers: (reducers: ReducersMapObject) => void,
  onMountApp: (appName: string) => void
) => {
  return React.lazy<
    React.ComponentType<{ store?: Store }> & { reducers?: ReducersMapObject }
  >(
    () =>
      new Promise((resolve) => {
        loadCss(`${getConfig('APP_ASSETS_URL')}static/css/${config.cssUri}`);

        SystemJS.import(config.app).then((_App: any) => {
          const App = _App.default;

          if (App.default.reducers) {
            addReducers(App.default.reducers);
          }
          onMountApp(config.app);
          resolve(App);
        });
      })
  );
};
