import { getConfig } from '../config/getConfig';

const isJSON = (response: Response) => {
  const contentType = response.headers.get('content-type');
  return contentType && contentType.indexOf('application/json') > -1;
};

const logFetchError = (error: Error) => {
  if (getConfig('ENV') === 'dev') {
    console.error(error);
  }
};

const handleResponse = (response: Response) => {
  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`);
  }

  if (!isJSON(response)) {
    throw new Error(
      `Respons is of type ${response.headers.get(
        'content-type'
      )}, expected is application/json`
    );
  }

  return response.json();
};

export const get = async <T>(
  input: RequestInfo,
  init?: RequestInit
): Promise<T> => {
  try {
    const response = await fetch(input, {
      ...init,
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
      },
    });

    return handleResponse(response);
  } catch (error) {
    logFetchError(error as Error);
    throw error;
  }
};
