import { PopoverOrigin } from '@material-ui/core';
import { TMenuPositionKey } from './TMenuPositionKey';

type TVerticalPos = 'top' | 'center' | 'bottom';
type THorizontalPos = 'left' | 'center' | 'right';
type TPosittionProps = {
  anchorOrigin: PopoverOrigin;
  transformOrigin: PopoverOrigin;
};

const invertHPos = (pos: THorizontalPos): THorizontalPos => {
  if (pos === 'left') {
    return 'right';
  }
  if (pos === 'right') {
    return 'left';
  }

  return pos;
};

const invertVPos = (pos: TVerticalPos): TVerticalPos => {
  if (pos === 'top') {
    return 'bottom';
  }
  if (pos === 'bottom') {
    return 'top';
  }

  return pos;
};

export const getMenuPosition = (key: TMenuPositionKey): TPosittionProps => {
  const [_vertical, _horizontal] = key.split('-');
  const vertical =
    _vertical === 'middle' ? 'center' : (_vertical as TVerticalPos);
  const horizontal =
    _horizontal === 'middle' ? 'center' : (_horizontal as THorizontalPos);

  return {
    anchorOrigin: {
      vertical,
      horizontal,
    },
    transformOrigin: {
      vertical: invertVPos(vertical),
      horizontal: vertical === 'center' ? invertHPos(horizontal) : horizontal,
    },
  };
};
