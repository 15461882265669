import { getConfig } from '../config/getConfig';
import { SystemJS } from '../SystemJS';
import { TAppConfig } from '../types/TAppConfig';

const staticImportMap: Record<string, string> = {
  react: 'app:react',
  '@reduxjs/toolkit': 'app:@reduxjs/toolkit',
  'react-dom': 'app:react-dom',
  'react-redux': 'app:react-redux',
  'react-router-dom': 'app:react-router-dom',
  history: 'app:history',
  '@sentry/react': 'app:@sentry/react',
  '@sentry/tracing': 'app:@sentry/tracing',
  '@fullstory/browser': 'app:@fullstory/browser',
};

SystemJS.set('app:react', require('react'));
SystemJS.set('app:@reduxjs/toolkit', require('@reduxjs/toolkit'));
SystemJS.set('app:react-dom', require('react-dom'));
SystemJS.set('app:react-redux', require('react-redux'));
SystemJS.set('app:react-router-dom', require('react-router-dom'));
SystemJS.set('app:history', require('history'));
SystemJS.set('app:@sentry/react', require('@sentry/react'));
SystemJS.set('app:@sentry/tracing', require('@sentry/tracing'));
SystemJS.set('app:@fullstory/browser', require('@fullstory/browser'));

export const initSystemJS = (configs: TAppConfig[]) => {
  const importMap: Record<string, string> = {
    ...staticImportMap,
  };
  configs.forEach((config) => {
    importMap[config.app] = `${getConfig('APP_ASSETS_URL')}static/js/${
      config.jsUri
    }`;
  });

  const originalResolve = SystemJS.constructor.prototype.resolve;
  SystemJS.constructor.prototype.resolve = (id: string, parentUrl: string) => {
    if (importMap[id]) {
      return importMap[id];
    }
    return originalResolve.call(SystemJS, id, parentUrl);
  };
};
