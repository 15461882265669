import React from 'react';
import {
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

type Props = {
  open: boolean;
  onClose?: () => void;
  closable: boolean;
  title: string;
  content: string;
  primary: {
    label: string;
    onClick: () => void;
  };
  secondary?: {
    label: string;
    onClick: () => void;
  };
};

export const Dialog: React.FC<Props> = (props) => {
  return (
    <MuiDialog
      open={props.open}
      onClose={props.closable ? props.onClose : undefined}
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {props.secondary && (
          <Button
            onClick={props.secondary.onClick}
            color="secondary"
            variant="outlined"
          >
            {props.secondary.label}
          </Button>
        )}
        <Button
          onClick={props.primary.onClick}
          color="primary"
          autoFocus
          variant="contained"
        >
          {props.primary.label}
        </Button>
      </DialogActions>
    </MuiDialog>
  );
};
